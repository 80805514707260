import { PageStateType, SchedulePageState, TimesheetPageState } from './page-params-state.model';

const ACTION_PREFIX = '[Page state]';
export const pageParamsActionType = {
  SET_FILTER: `${ACTION_PREFIX} set`,
  SET_SCHEDULE_VIEW_STATUS: `${ACTION_PREFIX} schedule view status`,
};

export class PageParamsAction {
  static setFilter(pageType: PageStateType, state: SchedulePageState | TimesheetPageState) {
    return {
      type: pageParamsActionType.SET_FILTER,
      payload: {
        pageType,
        state,
      },
    };
  }

  static setScheduleViewStatus(open: boolean) {
    return {
      type: pageParamsActionType.SET_SCHEDULE_VIEW_STATUS,
      payload: open,
    };
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../index';
import { PageParamsState, PageStateType, SchedulePageState, TimesheetPageState } from './page-params-state.model';
import { PageParamsAction } from './page-params.action';
import { createSelector } from 'reselect';

@Injectable()
export class PageParamsService {
  constructor(private store: Store<AppState>) {}

  setFilter(pageType: PageStateType, state: SchedulePageState | TimesheetPageState) {
    this.store.dispatch(PageParamsAction.setFilter(pageType, state));
  }
}

export const getPageParamsState = (appState: AppState): PageParamsState => appState.pageParamsState;

export const isScheduleViewOpen = createSelector(getPageParamsState, (pageFilters): boolean => pageFilters.scheduleViewOpen);
export const getTimesheetPageParams = createSelector(
  getPageParamsState,
  (pageFilters): TimesheetPageState => pageFilters.timesheet
);

export const getSchedulePageParams = createSelector(
  getPageParamsState,
  (pageFilters): SchedulePageState => pageFilters.schedule
);
